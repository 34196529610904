globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"9378dfc140e565b8656680110a14cda461327a86"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://2c5ff61b94b2359c23cd0a6d226c052b@o4506176641957888.ingest.us.sentry.io/4507994307624960",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.5,
  beforeSend(event, hint) {
    const exception = hint.originalException;

    // UnhandledRejection인지 확인하고 맞으면 전송하지 않음
    if (isCancleBeforePageRouteRefection(exception)) {
      return null;
    }

    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

function isCancleBeforePageRouteRefection(exception: unknown) {
  // 에러가 Non-Error 객체인지 확인하고, 메시지가 특정 값인지 확인
  if (
    !(exception instanceof Error) &&
    typeof exception === "string" &&
    exception.includes("router change canceled")
  ) {
    // 이 에러는 Sentry에 전송하지 않음
    return true;
  }

  return false;
}
