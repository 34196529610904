export const PRIME = {
  "259": {
    S1: "PRIME259S1",
    S2: "PRIME259S2",
    S2MUST: "PRIME259S2MUST",
    S2DC05: "PRIME259S2DC05",
    S2DC10: "PRIME259S2DC10",
    Y2: "PRIME259Y2",
    M1: "PRIME259M1",
    M2: "PRIME259M2",
    M3: "PRIME259M3",
    M4: "PRIME259M4",
    M990: "PRIME259M990",
    M4DC05: "PRIME259M4DC05",
    M4DC10: "PRIME259M4DC10",
    M4DC30: "PRIME259M4DC30",
    MS49: "PRIME259MS49",
    MS99: "PRIME259MS99",
    MS149: "PRIME259MS149",
    MS199: "PRIME259MS199",
    MS300: "PRIME259MS300",
  },
  "299": "PRIME299",
};
